/* Change this file to get your personal Porfolio */
import React from "react";
import { Link } from "react-router-dom";

// Website related settings
const settings = {
  isSplash: false, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "JMBekendam",
  description:
    "Hello there! I'm a full-stack developer with a passion for building both frontend and backend solutions.",
  og: {
    title: "Matthijs Bekendam Portfolio",
    type: "website",
    url: "http://jmbekendam.nl/",
  },
};

//Home Page
const greeting = {
  title: "JMBekendam",
  logo_name: "JMBekendam",
  nickname: "",
  subTitle: (
    <>
      {/* Welcome! As a developer, I help startups and freelancers bring their ideas
      to life with professional, custom-built websites. I also take on
      project-based backend development, specializing in Python to build secure,
      scalable systems. */}
      I'm a Python developer with a passion for building efficient, scalable,
      and user-friendly applications. With a strong foundation in data
      processing, web development, and automation, I enjoy tackling complex
      problems and turning ideas into code
    </>
  ),
  resumeLink:
    "https://drive.google.com/file/d/1bXRknv_h-XI_3CQ3SGPteGODtvEb7YvI/view?usp=sharing",
  portfolio_repository: " ",
  githubProfile: "",
};

const socialMediaLinks = [
  {
    name: "Github",
    link: "https://github.com/MatthijsBekendam",
    fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/matthijs-bekendam-b76047145/",
    fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },

  {
    name: "Gmail",
    link: "mailto:matthijsbekendam@gmail.com",
    fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  },
];

const skills = {
  data: [
    {
      title: "Full Stack Development",
      fileName: "FullStackImg",
      skills: [
        <>
          ⚡ Building responsive website front end using React (see{" "}
          <Link to="/projects">projects</Link>)
        </>,
        <>
          ⚡ Creating backend applications using Python (see{" "}
          <Link to="/experience">experience</Link>)
        </>,
      ],
      softwareSkills: [
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },

        {
          skillName: "Python",
          fontAwesomeClassname: "ion-logo-python",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
      ],
    },
    {
      title: "Cloud Infra-Architecture",
      fileName: "CloudInfraImg",
      skills: [
        <>
          ⚡ Experience working on multiple cloud platforms (see{" "}
          <Link to="/experience">experience</Link>)
        </>,
        <>
          ⚡ Hosting and maintaining websites on virtual machine instances along
          with integration of databases (see{" "}
          <Link to="/experience">experience</Link>)
        </>,
      ],
      softwareSkills: [
        {
          skillName: "GCP",
          fontAwesomeClassname: "simple-icons:googlecloud",
          style: {
            color: "#4285F4",
          },
        },
        // {
        //   skillName: "AWS",
        //   fontAwesomeClassname: "simple-icons:amazonaws",
        //   style: {
        //     color: "#FF9900",
        //   },
        // },
        {
          skillName: "Azure",
          fontAwesomeClassname: "simple-icons:microsoftazure",
          style: {
            color: "#0089D6",
          },
        },

        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "simple-icons:postgresql",
          style: {
            color: "#336791",
          },
        },
        {
          skillName: "Heroku",
          fontAwesomeClassname: "simple-icons:heroku",
          style: {
            color: "#47A248",
          },
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#1488C6",
          },
        },
        {
          skillName: "Kubernetes",
          fontAwesomeClassname: "simple-icons:kubernetes",
          style: {
            color: "#326CE5",
          },
        },
      ],
    },
    {
      title: "IoT and Embedded Systems Development",
      fileName: "DesignImg",
      skills: [
        <>
          ⚡ Raspberry Pi Automation: Implementing automation tasks using
          Raspberry Pi, such as executing custom scripts for home automation,
          environment monitoring, and more (see projects). (see{" "}
          <Link to="/projects">portfolio</Link>)
        </>,
        <>
          ⚡ LED Matrix Displays: Designing and programming LED matrices using
          Raspberry Pi for interactive displays, dynamic content, and visual
          notifications. (see <Link to="/projects">portfolio</Link>)
        </>,
      ],
      softwareSkills: [
        {
          skillName: "Ubuntu",
          fontAwesomeClassname: "simple-icons:ubuntu",
          style: {
            color: "#FF2BC2",
          },
        },

        {
          skillName: "Debian",
          fontAwesomeClassname: "simple-icons:debian",
          style: {
            color: "#FF7C00",
          },
        },

        {
          skillName: "Python",
          fontAwesomeClassname: "ion-logo-python",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
      ],
    },
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [
    {
      siteName: "LeetCode",
      iconifyClassname: "simple-icons:leetcode",
      style: {
        color: "#F79F1B",
      },
      profileLink: "https://leetcode.com/layman_brother/",
    },
    {
      siteName: "HackerRank",
      iconifyClassname: "simple-icons:hackerrank",
      style: {
        color: "#2EC866",
      },
      profileLink: "https://www.hackerrank.com/layman_brother",
    },
    {
      siteName: "Codechef",
      iconifyClassname: "simple-icons:codechef",
      style: {
        color: "#5B4638",
      },
      profileLink: "https://www.codechef.com/users/ashutosh_1919",
    },
    {
      siteName: "Codeforces",
      iconifyClassname: "simple-icons:codeforces",
      style: {
        color: "#1F8ACB",
      },
      profileLink: "http://codeforces.com/profile/layman_brother",
    },
    {
      siteName: "Hackerearth",
      iconifyClassname: "simple-icons:hackerearth",
      style: {
        color: "#323754",
      },
      profileLink: "https://www.hackerearth.com/@ashutosh391",
    },
    {
      siteName: "Kaggle",
      iconifyClassname: "simple-icons:kaggle",
      style: {
        color: "#20BEFF",
      },
      profileLink: "https://www.kaggle.com/laymanbrother",
    },
  ],
};

const degrees = {
  degrees: [
    {
      title: "Indian Institute of Information Technology Kurnool",
      subtitle: "B.Tech. in Computer Engineering",
      logo_path: "iiitk_logo.png",
      alt_name: "IIITDM Kurnool",
      duration: "2016 - 2020",
      descriptions: [
        "⚡ I have studied basic software engineering subjects like DS, Algorithms, DBMS, OS, CA, AI etc.",
        "⚡ Apart from this, I have done courses on Deep Learning, Data Science, Cloud Computing and Full Stack Development.",
        "⚡ I was selected for Merit cum Means Scholarship which is given to top 10% of students in college. I have received award from respected director for consistently best performance in academics.",
      ],
      website_link: "http://iiitk.ac.in",
    },
    {
      title: "Indiana University Bloomington",
      subtitle: "M.S. in Computer Science",
      logo_path: "iu_logo.png",
      alt_name: "Indiana University Bloomington",
      duration: "2021 - 2023",
      descriptions: [
        "⚡ I have taken varity of courses related to Artificial Intelligence which correspond to Explainable AI, Graph Machine Learning, Computer Vision etc.",
        "⚡ Apart from this, I have also done research assistantship. As part of it, I have worked on creating new algorithms in Graph ML and Network Science.",
        "⚡ During my time at university, I was also associated with multimedia department. As part of it, I have worked on some documentry films and interviews.",
      ],
      website_link: "https://www.indiana.edu/",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "Machine Learning",
      subtitle: "- Andrew Ng",
      logo_path: "stanford_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/22MTSSC5WDTM",
      alt_name: "Stanford University",
      color_code: "#8C151599",
    },
    {
      title: "Deep Learning",
      subtitle: "- Andrew Ng",
      logo_path: "deeplearning_ai_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/specialization/H8CPSFXAJD2G",
      alt_name: "deeplearning.ai",
      color_code: "#00000099",
    },
    {
      title: "ML on GCP",
      subtitle: "- GCP Training",
      logo_path: "google_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/specialization/EB4VJARK8647",
      alt_name: "Google",
      color_code: "#0C9D5899",
    },
    {
      title: "Data Science",
      subtitle: "- Alex Aklson",
      logo_path: "ibm_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/specialization/PLEAPCSJBZT5",
      alt_name: "IBM",
      color_code: "#1F70C199",
    },
    {
      title: "Big Data",
      subtitle: "- Kim Akers",
      logo_path: "microsoft_logo.png",
      certificate_link:
        "https://drive.google.com/file/d/164zKCFOsI4vGqokc-Qj-e_D00kLDHIrG/view",
      alt_name: "Microsoft",
      color_code: "#D83B0199",
    },
    {
      title: "Advanced Data Science",
      subtitle: "- Romeo Kienzler",
      logo_path: "ibm_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/BH2T9BRU87BH",
      alt_name: "IBM",
      color_code: "#1F70C199",
    },
    {
      title: "Advanced ML on GCP",
      subtitle: "- GCP Training",
      logo_path: "google_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/5JZZM7TNQ2AV",
      alt_name: "Google",
      color_code: "#0C9D5899",
    },
    {
      title: "DL on Tensorflow",
      subtitle: "- Laurence Moroney",
      logo_path: "deeplearning_ai_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/6T4DCUGNK8J8",
      alt_name: "deeplearning.ai",
      color_code: "#00000099",
    },
    {
      title: "Fullstack Development",
      subtitle: "- Jogesh Muppala",
      logo_path: "coursera_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/certificate/NRANJA66Y2YA",
      alt_name: "Coursera",
      color_code: "#2A73CC",
    },
    {
      title: "Kuberenetes on GCP",
      subtitle: "- Qwiklabs",
      logo_path: "gcp_logo.png",
      certificate_link:
        "https://google.qwiklabs.com/public_profiles/e4d5a92b-faf6-4679-a70b-a9047c0cd750",
      alt_name: "GCP",
      color_code: "#4285F499",
    },
    {
      title: "Cryptography",
      subtitle: "- Saurabh Mukhopadhyay",
      logo_path: "nptel_logo.png",
      certificate_link:
        "https://drive.google.com/open?id=1z5ExD_QJVdU0slLkp8CBqSF3-C3g-ro_",
      alt_name: "NPTEL",
      color_code: "#FFBB0099",
    },
    {
      title: "Cloud Architecture",
      subtitle: "- Qwiklabs",
      logo_path: "gcp_logo.png",
      certificate_link:
        "https://google.qwiklabs.com/public_profiles/5fab4b2d-be6f-408c-8dcb-6d3b58ecb4a2",
      alt_name: "GCP",
      color_code: "#4285F499",
    },
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "",
  description:
    "Hello there! This page is all about my showcasing my resume. By clicking the tabs below, you can see my work experience, skills and certifications.",

  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      work: true,
      experiences: [
        {
          title: "Backend Engineer",
          company: "ABN AMRO Bank (via Ordina/Sopra Steria)",
          company_url: "https://www.abnamro.nl/",
          logo_path: "abn_amro_logo.jpeg",
          duration: "Okt 2023 - Present",
          location: "Amersfoort",
          lineheight: 525,
          description: `
<p><strong>Situation</strong>: As a Python Engineer at Data Service Reporting, you work within a Data Warehouse environment on the user stories of Finance and Risk Mortgages. These include reporting requirements from the European Central Bank, De Nederlandsche Bank, the Dutch Authority for the Financial Markets, accountants, and internal auditors.</p>
<p><strong>Task:</strong></p>
<ul>
  <li>Professionalizing the internal Python tool 'AEG'
    <ul>
      <li>Setting up version control - GIT</li>
      <li>Setting up CI/CD pipeline within Azure
        <ul>
          <li>Unit testing (pytest)</li>
          <li>Dockerfile and azure-pipeline.yml</li>
          <li>Twistlock</li>
          <li>Formatting tools (Ruff, isort)</li>
          <li>SonarQube</li>
          <li>Fortify</li>
        </ul>
      </li>
      <li>Cloud migration (Azure) - with Azure Active Directory integration.</li>
      <li>Introduced unit- and integration tests.</li>
    </ul>
  </li>
  <li>Processing user stories for 'AEG' using Python and Django.</li>
</ul>
<p><strong>Results:</strong> When I started at Data Service Reporting, AEG was still a 'minimum viable product' and did not meet the application requirements of ABN AMRO main bank. The first thing I did was implementing unit- and system tests to increase the test coverage. Subsequently, I migrated the application to the cloud (Azure). Within Azure, I set up a CI/CD pipeline and worked in parallel on user stories for the application itself. As a result, AEG is no longer an 'MVP' and meets all of the main banks requirements.</p>
<p><strong>Specializations & methodologies:</strong> Python, Azure Pipelines, Azure DevOps, MS SQL Server, Django, CICD, Git, Docker, Linux</p>`,
          color: "#000000",
        },
        {
          title: "Backend Engineer",
          company: "VNG realisatie (via Ordina/Sopra Steria)",
          company_url: "https://vng.nl/",
          logo_path: "vng_realisatie_logo.jpeg",
          duration: "March 2022 - Oktober 2023",
          location: "Den Haag",
          lineheight: 435,
          description: `
<p><strong>Situation:</strong> VNG focuses on, among other things, designing, coordinating, establishing and maintaining API standards for municipalities. VNG Realization is the team within VNG that is involved in the reference implementations. The reference implementations of these standards are important to be able to show that the specification of a standard is in line with municipal practice.</p>
<p><strong>Task:</strong></p>
<ul>
  <li>Developing and maintaining existing API reference implementations according to an agile working method in accordance with the correct quality standards</li>
  <li>Translate business wishes into new reference implementations in consultation with colleagues, municipalities and their suppliers</li>
  <li>Writing test scripts based on test cases and documenting the produced software</li>
  <li>Developing Open Source software components in line with the Common Ground principles</li>
  <li>Setting up, managing and using a library with generic code</li>
</ul>
<p><strong>Results:</strong> As a developer within this team I worked on the maintenance and renewal of 8 APIs. I increased the quality and testability of the APIs and I was responsible for the implementation of new standards/data models. I also contributed to the development of the DevOps pipeline using Docker, Kubernetes and Github Actions.</p>
<p><strong>Specializations & methodologies:</strong> Python, SQL, Django, CICD, PostgreSQL, REST, Git, Kubernetes, Docker, FastAPI</p>`,
          color: "#0879bf",
        },
        {
          title: "Backend Engineer",
          company: "Luminext",
          company_url: "www.luminext.eu/en/",
          logo_path: "1631316838820.jpeg",
          duration: "Oktober 2021 - Februari 2022",
          location: "Bunnik",
          lineheight: 555,
          description: `
<p><strong>Situation:</strong> Luminext develops smart lighting systems for city streets and highways. I was responsible for developing Python daemons (control and message processing light points). Messages were processed using messaging protocols such as MQTT or AMQP. I also worked on the CI/CD pipeline.</p>
<p><strong>Task:</strong></p>
<ul>
  <li>Developing and maintaining the Python daemons.</li>
  <li>User-Interface development via React and Sanic (web framework).</li>
  <li>Setting up a new CI/CD pipeline in (JetBrains) TeamCity.</li>
  <li>Installing and maintaining new servers in Linux.</li>
</ul>
<p><strong>Results:</strong> During my period at Luminext I fully developed one python daemon that currently controls several light points in the Netherlands. The daemon is easy to operate by employees via a web application made in React.</p>
<p><strong>Specializations & methodologies:</strong> Python, React, Linux, SQL, MongoDB, CICD</p>`,
          color: "#0879bf",
        },
        {
          title: "Full Stack Developer",
          company: "Fleet Cleaner",
          company_url: "https://www.fleetcleaner.com/",
          logo_path: "fleet_cleaner_logo.jpeg",
          duration: "Juli 2019 - Okt 2021",
          location: "Delft",
          lineheight: 0,
          description: `
<p><strong>Situation:</strong> Fleet Cleaner develops underwater robots and drones to rinse biofouling from ships. Within the company I was responsible for all software processes. I mainly worked on navigation and auto-pilot systems of the robots. But also fully developed two web-applications.</p>
<p><strong>Task:</strong></p>
<ul>
  <li>Developing and maintaining Python software for:</li>
  <ul>
    <li>Cleaning robot</li>
    <ul>
      <li>User Interface (PyQt)</li>
      <li>Control Algorithm (i.e. auto-pilot)</li>
      <li>Control System</li>
    </ul>
    <li>Underwater drone</li>
    <ul>
      <li>User Interface (PyQt)</li>
      <li>Control System</li>
    </ul>
    <li>Automatic report generation software</li>
    <ul>
      <li>User Interface (PyQt)</li>
      <li>PDF generation via Latex</li>
      <li>Deep Learning for automatic photo selection (tensorflow)</li>
    </ul>
    <li>Web application development:</li>
    <ul>
      <li>Django REST</li>
      <li>React</li>
      <li>SQL</li>
      <li>Big Data</li>
    </ul>
  </ul>
</ul>
<p><strong>Results:</strong> Significant improvement made to the control software of the robots/drones (navigation, auto-pilot and UIs). Deep Learning model developed for the automatic report generation software. I was also in charge of developing two web applications focused on data analytics in combination with sales. The applications are still being used by company.</p>
<p><strong>Specializations & methodologies:</strong> Python, React, SQL, Django, Machine learning, Deep Learning</p>`,

          color: "#9b1578",
        },
      ],
    },
    {
      title: "Skills",
      experiences: [
        {
          title: "Machine Learning Intern",
          company: "TikTok Inc.",
          company_url: "https://www.tiktok.com/en/",
          logo_path: "tiktok_logo.png",
          duration: "May 2022 - Aug 2022",
          location: "San Francisco, USA",
          description:
            "Building new features on the backend recommendation system, specifically ranking algorithms for Ads that touch hundreds of millions of people around the world. Improving online and offline content ranking algorithms by performing hard sample data replays for training steps.",
          color: "#000000",
        },
        {
          title: "Data Science Research Intern",
          company: "Delhivery Pvt. Ltd.",
          company_url: "https://www.delhivery.com/",
          logo_path: "delhivery_logo.png",
          duration: "May 2019 - Sept 2019",
          location: "Gurgaon, Haryana",
          description:
            "I have worked on project of predicting freight rates based on previous data. There were two objectives: (1) To build a forecasting engine to predict daily freight rates. (2) To embed feature in the model which can explain the seasonal major changes in freight rate based on regions and locations. I have closely worked with deep learning models in combination with statistical methods to create solution for this. At the end of internship, I had created model deployed on AWS EC2 with the use of Kafka stream jobs, ElasticSearch and PostgreSQL.",
          color: "#ee3c26",
        },
        {
          title: "Data Science Intern",
          company: "Intel Indexer LLC",
          company_url:
            "https://opencorporates.com/companies/us_dc/EXTUID_4170286",
          logo_path: "intel_logo.jpg",
          duration: "Nov 2018 - Dec 2018",
          location: "Work From Home",
          description:
            "This is financial Solution Company. I have made Supervised Learning model for the company which can perform time series analysis on Stock price data for 32 companies. I have built LSTM Neural Networks Model and trained the data of 32 companies for last 2 years. This model is also used for forecasting.",
          color: "#0071C5",
        },
      ],
    },
    {
      title: "Certifications",
      experiences: [
        {
          title: "AZ-900 - Microsoft Azure Fundamentals",
          company: "Microsft",
          company_url: "https://www.microsoft.com",
          logo_path: "microsoft_logo.png",
          duration: "2022 June",
          location: "Nieuwegein",
          description:
            "This certification is designed to validate foundational level knowledge of cloud services and how those services are provided with Microsoft Azure. It covers general cloud concepts, Azure services, security, privacy, compliance, and underlying technology.",
          color: "#4285F4",
        },
        {
          title: "DP-900 - Microsoft Azure Data Fundamentals",
          company: "Microsoft",
          company_url: "https://www.microsoft.com/",
          logo_path: "microsoft_logo.png",
          duration: "June 2022",
          location: "Nieuwegein",
          description:
            "This certification is intended for candidates beginning to work with data in the cloud. It validates the skills and knowledge needed to work with relational and non-relational data on Microsoft Azure, including basic data concepts, working with data storage, and data processing.",
          color: "#D83B01",
        },
      ],
    },
    {
      title: "Education",
      experiences: [
        {
          title: "Master, Systems And Control",
          company: "TU Delft",
          company_url: "https://tudelft.nl",
          logo_path: "tudelft2.jpeg",
          duration: "2020",
          location: "Delft",
          description:
            "A Master's in Systems and Control focuses on developing advanced skills in designing, analyzing, and managing complex dynamic systems. This field combines elements of mathematics, engineering, and computer science to model and control processes in fields like robotics, manufacturing, and environmental systems. Machine learning plays a crucial role, as it enables the development of adaptive, data-driven models and algorithms that can improve the performance and robustness of control systems, making them more predictive and efficient.",
          color: "#4285F4",
        },
        {
          title: "Applied Physics",
          company: "Fontys Hogeschool Einhoven",
          company_url: "https://www.fontys.com/",
          logo_path: "fontys.png",
          duration: "2017",
          location: "Eindhoven",
          description:
            "A Bachelor's in Applied Physics provides a foundation in the principles of physics, emphasizing real-world applications in technology and engineering. This program covers topics like electromagnetism, thermodynamics, quantum mechanics, and materials science, with a focus on practical problem-solving and experimentation. Applied physics graduates often work at the intersection of scientific research and engineering, contributing to advances in areas such as electronics, materials development, and renewable energy.",
          color: "#D83B01",
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Portfolio",
  description:
    "This page showcases some of the projects I have created as a freelancer. Feel free to check them out! My work experience can be found on the 'Experience' page.",
  avatar_image_path: "projects_image.svg",
};

const publicationsHeader = {
  title: "IoT and Embedded Systems Development",
  description:
    "                    Here are some of the raspberrypi projects I have worked on.  ",
  avatar_image_path: "projects_image.svg",
};

const publications = {
  data: [
    {
      id: "CREPUSCULAIRE",
      name: "CREPUSCULAIRE (de haan studios)",
      createdAt: "2024-07-02T00:00:00Z",
      description:
        "LED matrix display controlled by a RaspberryPi. Users can upload photos of sunsets which are displayed on a 64x64 grid.",
      url: "https://www.studiodenhaan.com/",
    },
    // {
    //   id: "mdp-diffusion",
    //   name: "MDP-Diffusion",
    //   createdAt: "2023-09-19T00:00:00Z",
    //   description: "Blog published in Paperspace",
    //   url: "https://blog.paperspace.com/mdp-diffusion/",
    // },
    // {
    //   id: "consistency-models",
    //   name: "Consistency Models",
    //   createdAt: "2023-10-12T00:00:00Z",
    //   description: "Blog published in Paperspace",
    //   url: "https://blog.paperspace.com/consistency-models/",
    // },
  ],
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    // profile_image_path: "animated_ashutosh.png",
    profile_image_path: "mattie2.png",

    description: "Feel free to contact me!",
  },
  blogSection: {
    title: "Blogs",
    subtitle:
      "Shoot me a message - and I will get back to you as soon as possible!",
    link: "https://blogs.ashutoshhathidara.com/",
    avatar_image_path: "blogs_image.svg",
  },
  addressSection: {
    title: "Address",
    subtitle: "Wagenigen, Gelderland, Netherlands",
    locality: "Wageningen",
    country: "Netherlands",
    region: "Gelderland",
    postalCode: "6701TP",
    streetAddress: "Duikerlaan",
    avatar_image_path: "address_image.svg",
    location_map_link: "https://maps.app.goo.gl/FsA4LeGPDoJPVTFb6",
  },
  phoneSection: {
    title: "",
    subtitle: "",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  publicationsHeader,
  publications,
  contactPageData,
};
