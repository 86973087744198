import React, { useEffect } from "react";
import "./TopButton.css";

export default function TopButton({ theme }) {
  function GoUpEvent() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  function GoDownEvent() {
    window.scrollTo(0, document.body.scrollHeight);
  }
  useEffect(() => {
    scrollFunction();
  }, []);

  function scrollFunction() {
    if (
      document.body.scrollTop > 30 ||
      document.documentElement.scrollTop > 30
    ) {
      document.getElementById("topButton").style.visibility = "visible";
      document.getElementById("downButton").style.visibility = "hidden";
    } else {
      document.getElementById("topButton").style.visibility = "hidden";
      document.getElementById("downButton").style.visibility = "visible";
    }
  }

  window.onscroll = function () {
    scrollFunction();
  };

  const onMouseEnter = (color, bgColor, buttonId, arrowId) => {
    const button = document.getElementById(buttonId);
    button.style.color = color;
    button.style.backgroundColor = bgColor;

    const arrow = document.getElementById(arrowId);
    arrow.style.color = color;
    arrow.style.backgroundColor = bgColor;
  };

  const onMouseLeave = (color, bgColor, buttonId, arrowId) => {
    const button = document.getElementById(buttonId);
    button.style.color = color;
    button.style.backgroundColor = bgColor;

    const arrow = document.getElementById(arrowId);
    arrow.style.color = color;
    arrow.style.backgroundColor = bgColor;
  };

  return (
    <>
      <div
        onClick={GoUpEvent}
        id="topButton"
        style={{
          color: theme.body,
          backgroundColor: theme.text,
          border: `solid 1px ${theme.text}`,
        }}
        title="Go up"
        onMouseEnter={() =>
          onMouseEnter(theme.text, theme.body, "topButton", "upArrow")
        }
        onMouseLeave={() =>
          onMouseLeave(theme.body, theme.text, "topButton", "upArrow")
        }
      >
        <i className="fas fa-arrow-up" id="upArrow" aria-hidden="true" />
      </div>
      <div
        onClick={GoDownEvent}
        id="downButton"
        style={{
          color: theme.body,
          backgroundColor: theme.text,
          border: `solid 1px ${theme.text}`,
        }}
        title="Go down"
        onMouseEnter={() =>
          onMouseEnter(theme.text, theme.body, "downButton", "downArrow")
        }
        onMouseLeave={() =>
          onMouseLeave(theme.body, theme.text, "downButton", "downArrow")
        }
      >
        <i className="fas fa-arrow-down" id="downArrow" aria-hidden="true" />
      </div>
    </>
  );
}
