import React from "react";
import { HorizontalBar, Doughnut } from "react-chartjs-2";
import "./Skills.css";

const data1 = {
  labels: [
    "Flask",
    "Django",
    "FastAPI",
    "Pytest",
    "Unittest",
    "Pylint",
    "Celery",
  ],
  datasets: [
    {
      label: "Frameworks and libraries",
      data: [3, 5, 4, 5, 5, 4, 3, 15, 20, 10],
      backgroundColor: [
        "#050C9C",
        "#3572EF",
        "#3ABEF9",
        "#A7E6FF",
        "#050C9C",
        "#3572EF",
        "#3ABEF9",
      ],
    },
  ],
};

const data3 = {
  labels: ["PostgreSQL", "MySQL", "MongoDB", "MS SQL Server"],
  datasets: [
    {
      label: "Frameworks and libraries",
      data: [5, 5, 3, 5],
      backgroundColor: [
        "#050C9C",
        "#3572EF",
        "#3ABEF9",
        "#A7E6FF",
        "#050C9C",
        "#3572EF",
        "#3ABEF9",
      ],
    },
  ],
};

const data4 = {
  labels: [
    "React",
    "GIT",
    "Python",
    "Docker",
    "Kubernetes",
    "Linux",
    "Nginx",
    "Mapbox",
  ],
  datasets: [
    {
      label: "Frameworks and libraries",
      data: [3, 5, 5, 4, 3, 3, 3, 3],
      backgroundColor: [
        "#050C9C",
        "#3572EF",
        "#3ABEF9",
        "#A7E6FF",
        "#050C9C",
        "#3572EF",
        "#3ABEF9",
      ],
    },
  ],
};

const data2 = {
  labels: ["GCP", "Azure", "Heroku", "Digital Ocean"],
  datasets: [
    {
      label: "Cloud and Containerization Technologies",
      data: [3, 4, 3, 2],
      backgroundColor: [
        "#050C9C",
        "#3572EF",
        "#3ABEF9",
        "#A7E6FF",
        "#050C9C",
        "#3572EF",
        "#3ABEF9",
      ],
    },
  ],
};

const optionsBar = {
  scales: {
    yAxes: [
      {
        gridLines: {
          display: false,
        },
        scaleLabel: {
          display: true,
          // labelString: 'Skills'
        },
      },
    ],
    xAxes: [
      {
        ticks: {
          min: 1, // This sets the minimum value for the x-axis
          max: 5, // This sets the maximum value for the x-axis
        },
        scaleLabel: {
          display: true,
          labelString: "⭐", // This sets the label for the x-axis
        },
      },
    ],
  },
  legend: {
    display: false,
  },
  maintainAspectRatio: false,
};

const optionsDoughnut = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          max: 5, // This sets the maximum value for the y-axis
        },
        display: false,
        gridLines: {
          display: false,
        },
      },
    ],
    xAxes: [
      {
        ticks: {
          min: 1, // This sets the minimum value for the x-axis
          max: 5, // This sets the maximum value for the x-axis
          display: false, // Hide the labels
        },
        display: true,
        gridLines: {
          display: false,
        },
        scaleLabel: {
          display: true,
          labelString: "", // This sets the label for the x-axis
        },
      },
    ],
  },
  legend: {
    display: true, // Show the legend
    position: "top", // Position the legend at the top
    labels: {
      boxWidth: 20, // Width of the colored box
      padding: 15, // Padding between legend items
    },
  },
  tooltips: {
    enabled: true, // Enable tooltips
    callbacks: {
      label: function (tooltipItem, data) {
        const dataset = data.datasets[tooltipItem.datasetIndex];
        const currentValue = dataset.data[tooltipItem.index];
        return `${currentValue} ⭐ out of 5`;
      },
    },
  },
};

const Skills = () => (
  <div className="skills-container">
    <div className="skills-section">
      <h2>Frameworks and libraries</h2>
      <HorizontalBar data={data1} options={optionsBar} />
    </div>
    <div className="skills-section">
      <h2>Cloud and Containerization Technologies</h2>
      <div className="doughnut-container">
        <Doughnut data={data2} options={optionsDoughnut} />
      </div>{" "}
    </div>
    <div className="skills-section">
      <h2>Databases</h2>
      <HorizontalBar data={data3} options={optionsBar} />
    </div>
    <div className="skills-section">
      <h2>Tools & Technologies</h2>
      <HorizontalBar data={data4} options={optionsBar} />
    </div>
  </div>
);

export default Skills;
