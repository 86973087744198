import React, {useState} from 'react';
import {TextField, Grid} from '@mui/material';
import Button from "../../components/button/Button";


function ContactForm({theme}) {
    const [formData, setFormData] = useState({
        email: '',
        question: ''
    });

    const [wait, setWait] = useState(false);

    const handleChange = (event) => {
        const {name, value} = event.target;
        console.log(formData)

        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent the default form submit action

        try {
            console.log('Sending email:', formData);
            setWait(true)
            const response = await fetch('/.netlify/functions/email', {
                method: 'POST',
                body: JSON.stringify(formData),
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                // const responseBody = await response.json();
                alert('Email sent successfully!');
                setWait(false)
                // Here, you can also clear the form or handle the UI changes accordingly
            } else {
                alert('Failed to send email. Please try again later.');
                setWait(false)
            }
        } catch (error) {
            console.error('Error sending email:', error);
            alert('An error occurred. Please try again.');
            setWait(false)

        }
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                {/*<Paper style={{ padding: '2em', margin: 0, backgroundColor: '#F5F5F5' }}>*/}

                <form onSubmit={handleSubmit} style={{width: '100%'}} theme={theme}>
                    <TextField
                        fullWidth
                        label="Email"
                        variant="outlined"
                        name="email"
                        type="email"
                        value={formData.email}
                        onChange={handleChange}
                        style={{backgroundColor: 'white', marginBottom: '16px',}}
                    />
                    <TextField
                        fullWidth
                        label="Question"
                        variant="outlined"
                        name="question"
                        value={formData.question}
                        onChange={handleChange}
                        multiline
                        rows={4}
                        style={{backgroundColor: 'white', marginBottom: '16px'}}
                    />
                    <Button
                        type="submit"
                        text={wait ? 'Sending...' : 'Submit'}
                        onClick={handleSubmit}

                        theme={theme}/>
                    {/*<Button type="submit" color="primary" variant="contained" style={{ marginTop: '16px' }}>*/}
                    {/*  Submit*/}
                    {/*</Button>*/}
                </form>
                {/*</Paper>*/}
            </Grid>
        </Grid>
    );
}

export default ContactForm;
